import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import TypesMeditateCard from './TypesMeditateCard';
import Tilt from 'react-parallax-tilt';
import { AuthContext } from '../../../../context/AllContext';
import { useNavigate } from 'react-router-dom';

function StartMeditationComp() {
    const {isMobile} = useContext(AuthContext);
    const navigate = useNavigate()

    const cardColors3 = [
        'linear-gradient(135deg, #4A235A, #8E44AD)',  // Purple gradient
        'linear-gradient(135deg, #1F618D, #2980B9)',  // Blue gradient
        'linear-gradient(135deg, #117A65, #1ABC9C)',  // Teal gradient
        'linear-gradient(135deg, #2C3E50, #34495E)',  // Charcoal to dark grey gradient
        'linear-gradient(135deg, #4A235A, #8E44AD)',  // Purple gradient
        'linear-gradient(135deg, #1F618D, #2980B9)',  // Blue gradient
     
    ];

    const meditationTips = [
        {
          title: "Find a Quiet Space",
          content: "The first step in learning how to meditate is to create an environment conducive to relaxation. Choose a space in your home where you won't be disturbed. It doesn't have to be completely silent, but it should be a place where you feel comfortable and calm."
        },
        {
          title: "Start Small",
          content: "When you're new to meditation, less is more. A common misconception is that you need to meditate for hours on end. How to begin meditation should be gradual. Start with 5-10 minute sessions and slowly increase the duration as you feel more comfortable."
        },
        {
          title: "Sit Comfortably",
          content: "Learning how to meditate properly begins with physical comfort. Find a sitting posture that allows you to remain alert yet relaxed. You can sit cross-legged on the floor or on a chair with your feet flat on the ground. The key is to avoid strain, so that your body doesn’t distract you during meditation."
        },
        {
          title: "Be Consistent",
          content: "Meditation isn't about perfection, it's about persistence. To make meditation a part of your routine, try to meditate at the same time every day. Morning is a great time for many, as it sets a calm tone for the day ahead."
        },
        {
          title: "Experiment with Different Practices",
          content: "As you learn to meditate and grow more comfortable, don't hesitate to experiment with different forms of meditation. From mindfulness meditation to loving-kindness meditation, each practice offers its own unique benefits."
        },
        {
          title: "Take a Meditation Lesson",
          content: "If you wish to deepen your practice and learn how to meditate properly, consider taking a meditation lesson from an expert practitioner. You can also attend live classes or online webinars that can provide valuable feedback and personalized guidance."
        }
      ];
      
    return (
        <div>
            <div className='container d-flex justify-content-center align-items-center flex-column gap-2'>
                <h3 style={{
                    color: "black",
                    fontSize: isMobile?"2.5rem":"3.5rem",
                    textAlign:"center"
                }}
                    className='lora-sans'
                >
                    How can I start  {" "}
                    <span style={{ color: '#572ac7' }}>
                        Meditation
                    </span>
                </h3>
                <h6 style={{
                    color: "black",
                    fontSize: isMobile?"1.5rem":"2rem"
                }}
                    className='lora-sans text-center'
                >
                    Tips to Start Your Journey!

                </h6>
                <p className="robotofont mt-3 highlight-container text-start" style={{ fontSize: '1.1rem', fontWeight: "400" }}>
                    If you have made it this far, you are already curious about how to meditate and eager to begin your own meditation journey. So let’s break it down step by step, providing you with practical guidance on how to meditate properly, especially if you are looking for meditation for beginners.

                </p>

                <img
                       src='/img/bg/blogpages/Image20241003181410.jpg'
                        style={{
                            borderRadius: "15px",
                            boxShadow: "0 4px 10px rgba(128, 0, 128, 0.6)" // Purple box shadow
                        }}
                        alt='How-can-I-start-Meditation'
                        className='mt-3'
                    />
                <h6 style={{
                    color: "black",
                    fontSize: "2rem",
                    textAlign:"center"

                }}
                    className='lora-sans mt-5'
                >
                    Tips on How to Start Meditating


                </h6>
                <p className="robotofont mt-3 highlight-container text-start" style={{ fontSize: '1.1rem', fontWeight: "400" }}>
                Before jumping into any meditation techniques, it’s important to start with a basic understanding. Knowing how to start meditating can feel overwhelming, but the process is simpler than you think. Here are a few essential tips that will help guide you practice:


                </p>

                <Row className='justify-content-center align-items-center mt-2 gy-2'>
                    {
                        meditationTips.map((val, index) => (
                            <Col md={6}>

                                <TypesMeditateCard item={val} color={cardColors3[index]} img={false} />
                            </Col>
                        ))

                    }


                </Row>
                <p className="robotofont mt-3 highlight-container text-center" style={{ fontSize: '1.1rem', fontWeight: "400" }}>
                Now, if you are ready to explore meditation on a deeper level and prefer live instruction from experts, consider joining the Silva Method’s Weekly Live Interactive classes. These live meditation classes are led by certified instructors to guide you, providing hands-on support to enhance your practice. 
                </p>
                <button className='cssbuttons-io-button3' onClick={()=>{
                    navigate("/store/course/combo-plan")
                }}>
                    Try Silva Method

                </button>


            </div>
        </div>
    )
}

export default StartMeditationComp