import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { Col, Row } from 'react-bootstrap'

function BedTime() {
  return (
    <div style={{
        backgroundImage: 'linear-gradient(to right, #5B21B6, #3730A3)', // Equivalent to from-purple-800 to-indigo-800
        paddingTop: '3rem',  // Equivalent to py-12
        paddingBottom: '3rem',
        paddingLeft: '1rem',  // Equivalent to px-4
        paddingRight: '1rem',
        fontFamily: 'sans-serif',  // Equivalent to font-[sans-serif]
    }}
        className='d-flex justify-content-center align-items-center'
    >
        <Row className='container px-0 justify-content-center align-items-center'>
            <Col md={5}>
                <img src='/img/bg/blogpages/sleep-meditation.jpg'
                    style={{
                        width: "93%",
                        height: "93%",
                        borderRadius:"15px"
                    }}
                />

            </Col>
            <Col md={7}>
                <Fade duration={700}>
                    <h2 style={{ color: 'white', textAlign: 'start' }} className="lora-sans my-4">
                    Why is Bedtime  
                    <span style={{ color: '#FFEA00' }}>
                     {" "} Meditation
                        </span> Important?
                    </h2>

                    <p className="robotofont text-white mt-3 highlight-container" style={{ fontSize: '1.1rem' }}>
                    Meditation can be practiced at any time and anywhere, but bedtime meditation is particularly beneficial for those leading busy lives and struggling to achieve quality sleep. For working professionals, dedicating just five minutes at the end of your day to bedtime meditation can work wonders. As the day winds down, our minds can become cluttered with thoughts and worries, making it challenging to relax. Engaging in a bedtime meditation or a bedtime guided meditation practice helps quiet these mental distractions, allowing you to transition into a state of calm. 

                    </p>
                    
                    <p className="robotofont text-white mt-3 highlight-container" style={{ fontSize: '1.1rem' }}>
                    It’s essential to understand that meditation is not a quick escape from daily stress; it requires dedication and consistency. Bedtime meditation not only enhances your ability to relax but also cultivates a deeper connection with yourself, setting the stage for restorative sleep and a more peaceful tomorrow.

                    </p>
                </Fade>

            </Col>

        </Row>

    </div>
  )
}

export default BedTime