import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Tilt from 'react-parallax-tilt';
import ParaComp from '../../../SilvaManifestationProgram/ParaComp';
import TypesMeditateCard from './TypesMeditateCard';
import { AuthContext } from '../../../../context/AllContext';
import TypesMeditateCard3 from '../BlogLandingPage2/TypesMeditate3';
import { useNavigate } from 'react-router-dom';

function OtherMeditateSections() {
    const { isMobile } = useContext(AuthContext);

    const navigate = useNavigate()



    const cardColors3 = [
        'linear-gradient(135deg, #4A235A, #8E44AD)',  // Purple gradient
        'linear-gradient(135deg, #1F618D, #2980B9)',  // Blue gradient
        'linear-gradient(135deg, #117A65, #1ABC9C)',  // Teal gradient
        'linear-gradient(135deg, #2C3E50, #34495E)',  // Charcoal to dark grey gradient
        'linear-gradient(135deg, #4A235A, #8E44AD)',  // Purple gradient
        'linear-gradient(135deg, #1F618D, #2980B9)',  // Blue gradient
        'linear-gradient(135deg, #117A65, #1ABC9C)',  // Teal gradient
        'linear-gradient(135deg, #2C3E50, #34495E)',   // Charcoal to dark grey gradient
        'linear-gradient(135deg, #4A235A, #8E44AD)',  // Purple gradient
        'linear-gradient(135deg, #1F618D, #2980B9)',  // Blue gradient
        'linear-gradient(135deg, #117A65, #1ABC9C)',  // Teal gradient
        'linear-gradient(135deg, #2C3E50, #34495E)'   // Charcoal to dark grey gradient
    ];
    const colors = [
        "linear-gradient(to right, rgba(255, 235, 205, 0.9), rgba(255, 222, 173, 0.9))", // Light Peach to Navajo White
        "linear-gradient(to right, rgba(224, 255, 255, 0.9), rgba(175, 238, 238, 0.9))", // Light Cyan to Pale Turquoise
        "linear-gradient(to right, rgba(245, 245, 245, 0.9), rgba(220, 220, 220, 0.9))", // White Smoke to Gainsboro (Light Gray)
        "linear-gradient(to right, rgba(255, 248, 220, 0.9), rgba(255, 239, 213, 0.9))", // Cornsilk to Papaya Whip (Soft Yellow)
        "linear-gradient(to right, rgba(240, 255, 240, 0.9), rgba(224, 255, 255, 0.9))", // Honeydew to Light Cyan (Pale Green to Light Blue)
    ];
    const sectionRef = useRef(null);


    const meditationContent = [
        {
            title: "Mindfulness Meditation",
            content: "Mindfulness meditation is one of the most popular forms of meditation, especially in the West. It is also one of the most well-researched forms of meditation, perfect for beginners looking to explore how to meditate properly. Mindfulness majorly allows you to focus on your thoughts combining your concentration and awareness. Through this meditation technique, you can simply pay attention and be aware of each thought that passes through your mind without any judgment or taking immediate action toward it."
        },
        {
            title: "Transcendental Meditation (TM)",
            content: "Transcendental meditation (TM) differs from other forms of meditation techniques as you don't have to put in any extra effort to learn this meditation technique. Those who practice this particular meditation technique daily revealed that it is the most effortless way to meditate and is designed to allow your mind to settle naturally into a state of restful alertness. All you need to do is find a quiet space, sit comfortably, and silently repeat your mantra which acts as a cool tool to focus the mind and reach a state of deep relaxation."
        },
        {
            title: "Loving-Kindness Meditation (Metta)",
            content: "Loving-kindness meditation, also known as Metta meditation, particularly focuses on cultivating compassion, love, and goodwill, first towards yourself and then to others. To practice this meditation technique, simply find a quiet and comfortable place. Try to relax your mind and body with a relaxing meditation sound. As you settle, bring someone you care about into your mind, or even yourself, and silently affirm 'May you be healthy, May you be Happy, May you be at Peace'."
        },
        {
            title: "Body Scan Meditation",
            content: "The Body Scan meditation involves mentally scanning your body, moving your attention slowly from the top of your head to your toes. Through this meditation technique, your mind and body slowly and naturally get connected by promoting relaxation and awareness. This method teaches you how to practice meditation by directing attention to the present moment and reconnecting with your physical self. With regular practice, this meditation lesson can calm your mind, improve focus, and enhance your overall well-being."
        },
        {
            title: "Zen Meditation (Zazen)",
            content: "Zazen meditation, popularly known as zen meditation, is a practice rooted in ancient Buddhist traditions. This particular meditation technique emphasizes sitting upright with awareness of your breath, focusing on how it flows in and out of your belly. Even a 5-minute meditation session can create a calming effect and help develop mindfulness. To enhance the experience, you might want to try meditation music or relaxing meditation sounds."
        },
        {
            title: "Vipassana Meditation",
            content: "Vipassana meditation, India’s 2500-year-old meditation technique, encourages deeper self-awareness by observing the mind-body connection. Unlike other meditation practices, Vipassana isn't tied to any religious belief but is a practical technique for those seeking mental clarity and inner peace. Starting with a simple 5-minute meditation session is a great way to ease into the practice."
        },
        {
            title: "Chakra Meditation",
            content: "Chakra meditation is a powerful technique aimed at balancing the body’s energy centers. Each chakra is connected to different emotions and aspects of your life. Learning how to meditate in these centers can promote physical and emotional well-being. If you have a busy schedule, a 10-minute meditation can still offer significant benefits."
        },
        {
            title: "Mantra Meditation",
            content: "Mantra meditation is an Indian technique that uses a specific word, sound, or phrase, known as a 'Mantra'. To meditate using a mantra, simply repeat it silently or out loud, allowing it to anchor your thoughts. When paired with other meditation techniques or relaxing music, mantra meditation can be effective for reducing stress and encouraging deeper rest."
        },
        {
            title: "Yoga Meditation",
            content: "Yoga is a holistic practice that connects the mind, body, and spirit through breath control, physical postures, and meditation. Incorporating calming music can enhance the experience of yoga meditation, making it easier to release stress and tension. Regular practice leads to long-term benefits like emotional resilience and deeper self-awareness."
        },
        {
            title: "Relaxing Meditation Sound for Beginners",
            content: "Try the Silva Method relaxing meditation sound, an alpha-level sound that helps boost your concentration and focusing power while you meditate.",
            buttonText: "Relaxing Meditation sound - Play Alpha Sound"
        },
        {
            title: "Qigong Meditation",
            content: "Qigong meditation combines breath control, gentle movements, and focused attention to balance the body’s energy flow. It is a form of moving meditation that promotes emotional balance and enhances mental clarity. You can start with a 10-minute session by focusing on your breathing and moving your body slowly."
        },
    ];


   

    const quoteStyle = {
        margin: '20px 0',
        fontSize: '1.5em',
        fontStyle: 'italic',
        color: '#000',
    };



    const techniques = [
        {
            title: "Visualization Ability",
            img: "/img/bg/blogpages/visualization-ability.jpg",
            alt:"Visualization-Ability",
            content: "A study on Buddhist meditation has shown how your visual sensitivity can significantly improve with daily meditation practice. Further studies have found results like lower visual thresholds and better hearing abilities after meditation. Another important aspect that has been found is that the ability to visualize and imagine also improves. You will also experience fewer mental distractions and respond faster."
        },
        {
            title: "Memory and Intelligence",
            img: "/img/bg/blogpages/Body-Scan.jpg",
            alt:"Memory-and-Intelligence",
            content: "Our memory and intelligence somehow reflect how healthy our mind is. If you tend to misplace things or keep forgetting important information, whether at work, school or in an exam hall, then there are chances your mind is filled with other “not so important” information. Researchers have shown that practicing meditation, especially transcendental meditation, performs 5X better in non-verbal intelligence tests. Similar improvements in thinking abilities, reasoning, problem-solving, academic performance, and positive effects on intelligence have also been found in scientific studies."
        },
        {
            title: "Creativity and Self-Actualization",
            img: "/img/bg/blogpages/visualization.jpg",
            alt:"Creativity-and-Self-Actualization",
            content: "When it comes to creativity, studies have shown mixed results. In some cases, daily meditation has shown a significant boost in creativity and imagination skills in an individual. However, many online resources show meditating at a deeper level of consciousness enhances creativity and intuitive abilities. On the other hand, self-actualization, i.e., harnessing your full potential, is considered the key goal of dedicated meditation practitioners. Consistent meditation practice surprisingly boosts the ability to harness one’s true potential by enhancing emotional maturity and developing a holistic view of oneself and the world."
        },
        {
            title: "Bottom Line",
            img: "/img/bg/blogpages/progressive-muscle.jpg",
            alt:"Bottom-Line",
            content: " Meditation is not just a spiritual or mental practice; it's a scientifically backed tool that profoundly affects your mind and body. You can feel certain changes immediately, such as reduced stress and better emotional balance, while deeper and long-term changes are happening inside your brain. Shrinking of the amygdala, increased blood flow to the brain, and the reduction of stress-related chemicals in the blood are just a few examples of how meditation transforms us internally. What’s even more fascinating is how meditation transforms our psychology by sharpening our ability to visualize, think, and respond instead of react. Whether you're looking to improve your mental clarity, boost creativity, or simply live a more balanced life, meditation has something valuable to offer. Related and famous meditation platforms like the Silva Method offer such a holistic approach to its seekers, helping them harness their full potential and guiding them on the path to self-actualization. Dive into it, and with time, the effects will speak for themselves."
        },

    ];



    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate-highlights');
                    } else {
                        entry.target.classList.remove('animate-highlights');
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);


    return (
        <>
            <div className="container py-5 d-flex justify-content-center align-items-center flex-column" style={{
                marginTop: isMobile ? "0%" : "0px"
            }}>
                <div style={{ width: isMobile ? "100%" : '70%' }}>
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <Card className="medicardsection d-flex justify-content-center align-items-start p-4 position-relative" style={{
                            width: '100%',
                            backgroundImage: `url("/img/bg/bghero/icons/tkl7pdrwpwaugjybanxn.jpg")`,
                            backgroundSize: 'cover',  // Ensures the image covers the card
                            backgroundPosition: 'center',  // Centers the image
                            color: 'white',  // Ensures text is white for better contrast
                            borderRadius: "15px"
                        }}>
                            {/* Overlay */}
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',  // Black overlay with 0.6 opacity
                                    zIndex: 1,
                                }}
                            />

                            {/* Card content */}
                            <div style={{ position: 'relative', zIndex: 2, padding: "10px" }}>
                                <h2 style={{ color: 'white', textAlign: 'start' }} className="lora-sans">
                                    What is{' '}
                                    <span style={{ color: '#c2aff0' }}>
                                        Meditation
                                    </span>
                                </h2>

                                <h3 style={{ color: 'white',fontWeight:"600",fontSize:"1.5rem" }} className="robotofont mt-2 text-white text-start">
                                    The Meditation Definition!
                                </h3>

                                <p ref={sectionRef} className="robotofont text-white mt-2 highlight-container" style={{ fontSize: '1.1rem' }}>
                                    Meditation is a discipline where you carve out time from your busy schedule to reconnect with your inner self,
                                    finding peace and clarity. If you're wondering <span className="fw-bold">how to meditate</span>,
                                    it starts with creating a moment of stillness. Think of it as spending quality time with your mind.
                                    To truly grasp the   <span className="fw-bold">meditation techniques</span> and the essence of this practice, one must indulge in the
                                    daily practice of meditation.
                                    The more you meditate, the more aware you become of your thoughts and the better you understand your emotions.
                                    Daily meditation helps regulate overwhelming emotions and cultivate kindness and compassion towards yourself and the rest of the world.
                                    Meditation is the key to finding peace in the chaos of today's fast-paced world.
                                    As you embrace <span className="fw-bold">meditation for beginners,</span> you'll learn to handle life's challenges with grace,
                                    responding thoughtfully instead of reacting impulsively.
                                </p>
                            </div>
                        </Card>



                    </div>
                </div>

            </div>

            <div style={{
                backgroundImage: 'linear-gradient(to right, #5B21B6, #3730A3)', // Equivalent to from-purple-800 to-indigo-800
                paddingTop: '3rem',  // Equivalent to py-12
                paddingBottom: '3rem',
                paddingLeft: '1rem',  // Equivalent to px-4
                paddingRight: '1rem',
                fontFamily: 'sans-serif',  // Equivalent to font-[sans-serif]
            }}
                className='d-flex justify-content-center align-items-center'
            >
                <Row className='container px-0 justify-content-center align-items-center'>
                    <Col md={5}>
                        <img src='https://silvamethod.com/img/bg/bghero/bottom-image.webp'
                            style={{
                                width: "100%",
                                height: "100%"
                            }}
                            alt='how-to-meditate'
                        />

                    </Col>
                    <Col md={7}>
                        <Fade duration={700}>
                            <h2 style={{ color: 'white', textAlign: 'start' }} className="lora-sans">
                                How to   <span style={{ color: '#FFEA00' }}>
                                    Meditate
                                </span> Properly
                            </h2>

                            <p className="robotofont text-white mt-3 highlight-container" style={{ fontSize: '1.1rem' }}>
                                There is no such answer to how to meditate properly. However, to understand the connection between meditation and psychology, one must explore the perceptual and cognitive abilities associated with different meditation for beginners even if you do that. You will find no right or wrong way of meditation in response to how to meditate properly. A few types of meditation will train your mind to focus and concentrate better. You'll have to focus on a specific thing. It could be your breath or a mantra that you can chant while meditating. On the other hand. Other types of meditation help you become more aware and mindful of your thoughts, remove worries about the past and future, and focus on the present moment.
                            </p>
                        </Fade>

                    </Col>

                </Row>

            </div>
            <div className="container py-5 d-flex justify-content-center align-items-center flex-column">
                <h2 style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: isMobile ? "2rem" : "2.5rem"
                }}
                    className='lora-sans'
                >
                    How to practice {" "}
                    <span style={{ color: '#572ac7' }}>
                        Meditation
                    </span>
                </h2>

                <p className='text-dark robotofont mt-3' style={{
                    fontSize: "1rem",
                    textAlign: isMobile ? "start" : "center"
                }}>
                    The Psychological effects of meditation are really surprising, as many scientists across the world investigated various meditation techniques for beginners and advanced-level practitioners. Now, If you are a beginner and wondering how to start meditating, here are a few quick tips for you:
                </p>
                <Tilt>
                    <img
                       src='/img/bg/bghero/1 Oct - Practrice Meditation.jpg'
                        style={{
                            borderRadius: "15px",
                            boxShadow: "0 4px 10px rgba(128, 0, 128, 0.6)" // Purple box shadow
                        }}
                        className='mt-3'
                        alt='How-to-practice-Meditation'
                    />
                </Tilt>


                <div style={{
                    width: "100%",
                    height: "1px",

                    background: "lightgrey"
                }} className='my-5'>

                </div>

                <Row className='justify-content-center align-items-center '>

                    {[
                        {
                            title: "Consistency is key",
                            content: "Just like any other skill, consistency makes you perfect. Similarly for meditation consistency is the key. Especially for beginners, even a 10-minute meditation practice can bring holistic changes to your mental and physical health. You can start with a 5-10-minute practice session then gradually increase your practice by 10- mins. Meditating a few times a week is okay and it’s also okay if you miss a day or two. Simply pick up where you left off."
                        },
                        {
                            title: "Meditate, wherever whenever you can",
                            content: "Meditation is a mind state of pure calmness and bliss. You don’t need a specific place and time to meditate. Meditation can be practiced at any time anywhere if you master the art of focusing and managing your thoughts. Now, if you meditate before you start your day .. before going to work .. after you finish off your day, and before going to bed. You will benefit to the maximum .. similarly. For beginners its more easy to meditate in a quiet and comfortable place .. so that there’s less noise and distractions. "
                        },
                        {
                            title: "Get comfortable and pay attention.",
                            content: "Talking about the sitting position, you can simply sit on your couch in a cross-legged position or you can sit on a chair. The ultimate goal is to make your mind and body as comfortable and relaxed as possible. The more relaxed you feel in your mind and body, the more you will be able to focus and enter a deep state of relaxation where external distractions will automatically go away. So start with what makes you comfortable, pay attention, and continue to do that."
                        },
                        {
                            title: "Start with Guided Meditation for Beginners",
                            content: "If you are thinking how can you meditate.. Guided meditation is amazingly helpful for both beginners and advanced. In guided meditation, you will receive guidance directly from experts from recorded audio. They will break down what to do throughout the meditation lessons and will motivate you in each step giving you tips on different meditation lessons. To start with guided meditation, you can try the Silva Method Digital Audio Programs. With more than 50+ guided meditation audio programs, you can practice anytime where per your comfort."
                        },

                    ].map((item, index) => (
                        <Col md={6}>
                            <div key={index} className="card mb-4 shadow-sm mx-auto"
                                style={{
                                    background: "#f5f4ef",
                                    transition: 'all 0.3s ease',
                                    border: 'none',
                                    borderRadius: '25px',
                                    padding: isMobile ? "10px" : "20px",
                                    overflow: 'hidden',
                                    width: "95%",
                                    height: isMobile ? "auto" : "380px",
                                }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.transform = 'translateY(-5px)';
                                    e.currentTarget.style.boxShadow = '0 10px 20px rgba(0,0,0,0.1)';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.transform = 'translateY(0)';
                                    e.currentTarget.style.boxShadow = '0 4px 6px rgba(0,0,0,0.1)';
                                }}>
                                <div className="card-body">

                                    <h2 style={{ color: 'black', textAlign: 'start', fontSize: isMobile ? "1.5rem" : "2rem" }} className="lora-sans">
                                        {item.title}
                                    </h2>
                                    <p className="robotofont mt-3 highlight-container" style={{ fontSize: '1rem' }}>{item.content}</p>
                                </div>
                            </div>
                        </Col>
                    ))}

                </Row>


                <button className='cssbuttons-io-button3' onClick={()=>{
                    navigate("/store/course/combo-plan")
                }}>
                    TRY NOW

                </button>

                <div style={{
                    width: "100%",
                    height: "1px",

                    background: "lightgrey"
                }} className='my-5'>

                </div>

                <ParaComp
                    data={[
                        <p className="robotofont mt-3 highlight-container" style={{ fontSize: '1rem', fontWeight: "500" }}>
                            There is no proven scientific fact like a theorem for meditation. However, tons of scientific studies, brain scans, and research indicate the effects of meditation on human psychology. When you begin to practice meditation like a ritual, you will certainly feel its effects on your emotional and physical well-being externally. However, internally, your brain will also go through certain changes. But what exactly changes when you learn how to meditate?
                        </p>,
                        <p className="robotofont mt-3 highlight-container" style={{ fontSize: '1rem', fontWeight: "500" }}>
                            The amygdala, a part of your brain responsible for creating fear, anxiety, and stress-related emotions, becomes smaller in size with daily meditation practice. Further, using modern scientific tools like FMRI and EEG techniques, many studies have shown that blood flow into the brain increases just after two months of daily meditation. In addition, interleukin-6, a chemical formed in our blood when we are stressed or feeling overwhelmed or anxious, decreases for those who continue to meditate for more than two to three months. Apart from this, other psychological effects of meditation are important to understand. Let’s dive deeper and explore the connection between psychology and meditation:
                        </p>,
                    ]}
                />


                <Row className='justify-content-center align-items-center my-5 gy-2'>
                    {
                        techniques.map((val, index) => (
                            <Col md={12}>

                                <TypesMeditateCard3 item={val} color={"#f5f4ef"} imgSrc={val.img} black altTag={val.alt}/>
                            </Col>
                        ))

                    }


                </Row>






                <div className="container py-5 d-flex justify-content-center align-items-center flex-column">
                    <h3 style={{
                        color: "black",
                        fontSize: isMobile ? "2rem" : "3.5rem"
                    }}
                        className='lora-sans'
                    >
                        Types of  {" "}
                        <span style={{ color: '#572ac7' }}>
                            Meditation
                        </span>
                    </h3>

                    <p className='text-dark robotofont mt-3 text-center' style={{
                        fontSize: isMobile ? "1rem" : "1.1rem"
                    }}>
                        Now that you are aware of the profound benefits and effects of meditation on the human brain and psychology. It’s important to learn about the different forms and types of meditation practiced across the world. However, there is no best type or most effective type of meditation because it varies from person to person. What feels best for you may not work out for others that well. So what you can do is explore the different forms of meditation and then identify the one that meets your needs and requirements and provides maximum benefits to you. To begin your journey here’s a list of all types of meditation:
                    </p>
                        <img
                            src='/img/bg/blogpages/Image20241001185244.jpg'
                            style={{
                                borderRadius: "15px",
                                boxShadow: "0 4px 10px rgba(128, 0, 128, 0.6)" // Purple box shadow
                            }}
                            className='mt-3'
                            alt='Types-of-Meditation'
                        />

                    <Row className='justify-content-center align-items-center my-5 gy-2'>
                        {
                            meditationContent.map((val, index) => (
                                <Col md={6}>

                                    <TypesMeditateCard item={val} color={cardColors3[index]} />
                                </Col>
                            ))

                        }


                    </Row>

                    <p className='text-dark robotofont text-start' style={{
                        fontSize: "1.1rem"
                    }}>
                        Whether you are new to meditation or have been practicing for years, there is something for everyone,
                        from meditation for beginners like mindfulness and body scan to more advanced techniques like Zen and
                        Vipassana. Exploring different types of meditation allows you to find the method that resonates with
                        your needs, whether you seek stress relief, deeper self-awareness, or emotional healing.
                    </p>

                    <blockquote style={quoteStyle}>
                        "Meditation is a way to be in the present moment, to let go of stress, and to find peace within."
                    </blockquote>
                    <p className='text-dark robotofont mt-3 text-start' style={{
                        fontSize: "1.1rem"
                    }}>
                        So here’s a quick tip: Start with short sessions, incorporate relaxing music or guided meditation
                        practices, and discover the profound impact meditation can have on your well-being.
                    </p>
                </div>





            </div>

           
        </>
    );
}

export default OtherMeditateSections;
