import React from 'react';
import { useContext } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { AuthContext } from '../../../../context/AllContext';
import TypesMeditateCard from '../BlogLandingPage1/TypesMeditateCard';
import Tilt from 'react-parallax-tilt';
import TypesMeditateCard2 from './TypesMeditateCard2';

const MeditationTechniques = () => {
  const { isMobile } = useContext(AuthContext);

  const cardColorsNew = [
    'linear-gradient(135deg, #8E44AD, #5E3370)',  // Deep purple gradient
    'linear-gradient(135deg, #2980B9, #1C6698)',  // Deep blue gradient
    'linear-gradient(135deg, #27AE60, #1E7C4A)',  // Deep green gradient
    'linear-gradient(135deg, #C0392B, #9A2A1D)',  // Deep red gradient
    'linear-gradient(135deg, #6F2C91, #5D1F7A)',  // Deep lavender gradient
    'linear-gradient(135deg, #1C4D88, #153C66)',  // Deep teal gradient
    'linear-gradient(135deg, #005D52, #003D39)',  // Dark emerald gradient
    'linear-gradient(135deg, #34495E, #2C3E50)',  // Dark slate grey gradient
    'linear-gradient(135deg, #4A3C30, #3A2B25)',  // Dark brown gradient
    'linear-gradient(135deg, #8C3F3A, #68272B)',  // Dark burgundy gradient
    'linear-gradient(135deg, #2C3E50, #1A242F)',  // Dark navy gradient
    'linear-gradient(135deg, #7D3C98, #5B2C6F)'   // Dark purple gradient
  ];

  const techniques = [
    {
      title: "Deep Breathing",
      img:"/img/bg/blogpages/deep-breathing1-1.jpg",
      content: "Focusing on your breath is one of the simplest and most powerful techniques. By concentrating on your inhalations and exhalations, you create a calming rhythm that soothes the mind, helping you drift into sleep."
    },
    {
      title: "Body Scan",
      img:"/img/bg/blogpages/Body-Scan.jpg",
      content: "The body scan technique encourages relaxation by guiding you to focus on different parts of your body sequentially. Consciously relax each muscle group, releasing physical tension and fostering mindfulness."
    },
    {
      title: "Visualization",
      img:"/img/bg/blogpages/visualization.jpg",
      content: "Guided sleep meditations often use visualization techniques. Imagine serene landscapes like a tranquil beach or peaceful forest to help your mind escape daily stress and foster deep sleep."
    },
    {
      title: "Progressive Muscle Relaxation",
      img:"/img/bg/blogpages/progressive-muscle.jpg",
      content: "This technique involves tensing and then relaxing each muscle group in your body. By consciously releasing tension, you signal to your body that it’s time to rest."
    },
    {
      title: "Guided Imagery",
      img:"/img/bg/blogpages/guided-imagery.jpg",
      content: "Listening to a soothing voice guiding you through a relaxing scenario can significantly enhance your meditation. Narrations help redirect your thoughts and create a tranquil mental space."
    },
    {
      title: "Sound and Music",
      img:"/img/bg/blogpages/Sound-and-Music-1-1.jpg",
      content: "Many guided meditations for sleep incorporate calming sounds, like nature or soft music. These sounds can lull you into a deep state of relaxation."
    },
    {
      title: "Positive Sleep Affirmations",
      img:"/img/bg/blogpages/Positive-sleep-affirmations1-1.jpg",
      content: "Positive affirmations can promote peace and safety. Phrases like 'I am calm and at peace' or 'I release the day and welcome restful sleep' help reprogram your subconscious for relaxation."
    },
  ];

  return (
    <div className="py-5 container">
      <h3 style={{
        color: "black",
        fontSize: isMobile ? "2rem" : "3rem"
      }}
        className='lora-sans text-center '
      >
        Common  Deep Sleep   {" "}
        <br />
        <span style={{ color: '#572ac7' }}>
          Guided Meditation  
        </span> Techniques
      </h3>

      <div className='d-flex justify-content-center align-items-center'>
      <Tilt>
        <img
          src='/img/bg/blogpages/Common-Deep-Sleep-Guided-Meditation-Techniques.jpg'
          style={{
            borderRadius: "15px",
            boxShadow: "0 4px 10px rgba(128, 0, 128, 0.6)" // Purple box shadow
          }}
          className='mt-3'
        />
      </Tilt>

      </div>

      <p className={isMobile?"robotofont mt-4":"robotofont px-5 mt-4"} style={{
        fontSize: isMobile ? "1rem" : "1.1rem",
        textAlign: "center"
      }}>
        Guided meditation for sleep aims to calm the mind, release tension, and create a peaceful mental state, preparing you for restorative rest. Here are some common techniques that can help you drift into a deep, peaceful slumber and wake up refreshed.

      </p>
      <Row className='justify-content-center align-items-center my-5 gy-2'>
        {
          techniques.map((val, index) => (
            <Col md={6}>

              <TypesMeditateCard2 item={val} color={"#f5f4ef"} imgSrc={val.img} black />
            </Col>
          ))

        }


      </Row>
    </div>
  );
};

export default MeditationTechniques;
