import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { FaHeart } from 'react-icons/fa';
import Tilt from 'react-parallax-tilt';
import { useNavigate } from 'react-router-dom';

function BenefitsSection3() {

    const navigate = useNavigate()

    const benefits = [
        {
            title: "Reduces Stress",
            description:
                "Healing meditation helps lower stress levels, which is crucial for anyone battling illnesses. Chronic stress weakens the immune system, making it harder for the body to heal naturally. Practicing healing meditation can create a calmer mind and promote physical healing.",
            icon: "/img/bg/bghero/icons/reduce stress.png" // Change this to your actual icon path
        },
        {
            title: "Boosts Immunity",
            description:
                "Regular meditation has been shown to strengthen the immune system for healing the mind and body. Through the focused breathing and relaxation techniques in guided meditation for healing, the body enters a state of deep rest, allowing the immune system to function optimally, which is essential for fighting diseases.",
            icon: "/img/bg/bghero/icons/boost immunity.png" // Change this to your actual icon path
        },
        {
            title: "Enhances Emotional Balance",
            description:
                "Healing meditation is not just about physical health—it plays a significant role in emotional healing as well. The practice helps individuals process grief, fear, and anxiety, especially those facing life-threatening diseases, leading to emotional stability and resilience.",
            icon: "/img/bg/bghero/icons/emotional balance.png" // Change this to your actual icon path
        },
        {
            title: "Supports Pain Management",
            description:
                "Many people experience chronic pain, whether from injuries, diseases, or stress. Self healing meditation techniques can help reduce the perception of pain, making it easier for individuals to manage physical discomfort and improve their quality of life.",
            icon: "/img/bg/bghero/icons/Supports Pain Management.png" // Change this to your actual icon path
        },
        {
            title: "Promotes Cellular Regeneration",
            description:
                "When the mind is calm, the body can focus its energy on healing. Studies suggest that healing meditation may promote the regeneration of healthy cells, which is critical for those undergoing treatments for serious illnesses.",
            icon: "/img/bg/bghero/icons/cellular regeneration.png" // Change this to your actual icon path
        },
        {
            title: "Improves Sleep Quality",
            description:
                "Insomnia is a common issue for individuals dealing with mental and physical health conditions. Healing meditation can encourage better sleep patterns by calming the mind and relaxing the body, facilitating natural healing processes during rest.",
            icon: "/img/bg/bghero/icons/Improves sleep quality.png" // Change this to your actual icon path
        },
        {
            title: "Balances Energy",
            description:
                "Meditation helps align the body's energy, reducing blockages and ensuring a smooth flow of life force. This balance contributes to healing and helps individuals maintain vitality, even in the face of severe health challenges.",
            icon: "/img/bg/bghero/icons/balance energy.png" // Change this to your actual icon path
        },
        {
            title: "Increases Mind-Body Awareness",
            description:
                "Self healing meditation encourages greater awareness of the body and its needs. Individuals learn to listen to their bodies, recognizing early signs of stress or illness. By awakening your body’s natural healing mechanism, you can prevent further health complications by healing the mind and body.",
            icon: "/img/bg/bghero/icons/Increase Mind body awareness.png" // Change this to your actual icon path
        },
        {
            title: "Improves Focus and Clarity",
            description:
                "Healing meditation helps quiet the mind and sharpens focus, which can be essential for making informed health decisions. Whether choosing treatment options or managing day-to-day challenges, clarity of mind aids in navigating difficult situations.",
            icon: "/img/bg/bghero/icons/Improves focus and clarity.png" // Change this to your actual icon path
        },
        {
            title: "Facilitates Emotional Release",
            description:
                "Healing meditation allows individuals to release trapped emotions, such as anger, frustration, or sadness, which may otherwise hinder healing. This emotional release is a crucial step in coping with chronic illnesses or life-threatening conditions.",
            icon: "/img/bg/bghero/icons/Emotional releases.png" // Change this to your actual icon path
        }
    ];

    return (
        <div className='container'>
            <div className='d-flex justify-content-center align-items-center flex-column'>
                <h2 className='lora-sans'>
                    10 Benefits of Healing Meditation

                </h2>
                <Tilt>

                    <img src='/img/bg/bghero/icons/nsq4lmsjbmfvdztmrjnw.jpg' className='mt-3' style={{
                        borderRadius: "15px"
                    }} />
                </Tilt>

                <p className='robotofont mt-3'>
                    Even doctors agree that medicine should be the last resort because our body has an incredible ability to heal itself, given the right conditions. From common ailments like coughs and colds to more severe conditions like cancer, our bodies are equipped with a natural healing mechanism that works alongside medical treatment. However, if you're feeling overwhelmed by endless doctor visits and rising healthcare costs, healing meditation can be a powerful tool to accelerate your recovery, up to five times faster.
                </p>
                <p className='robotofont'>
                    This doesn’t mean abandoning medication but rather awakening your body’s innate self-healing abilities. By practicing self healing meditation consistently, you can unlock profound physical, emotional, and spiritual benefits for healing the mind and body. Guided meditation for healing allows you to connect deeply with yourself, fostering balance and harmony throughout your mind and body. Here are ten ways healing meditation can contribute to natural healing, help individuals cope with serious health issues, and support overall mental wellness:
                </p>

                <Row className='container justify-content-center align-items-center gy-3'>
                    {
                        benefits.map((val) => (
                            <Col xs={12} md={6} lg={4}>
                                <div className="healing-card">
                                    <div className="healing-icon-container">
                                        <img src={val.icon} className='healing-card-icon' width={100} />
                                      
                                    </div>
                                    <div className="healing-card-content">
                                        <h3 className="healing-card-title">{val.title}</h3>
                                        <p className="healing-card-description">
                                            {val.description}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }


                </Row>

                <div className='mt-3'>
                    <p className='robotofont'>
                    By integrating healing meditation into daily life, individuals can not only support their natural healing process but also gain emotional and mental clarity, enhancing their overall well-being for healing the mind and body. Whether through guided meditation for healing or practicing self healing meditation on your own, this holistic approach offers a path toward inner peace and resilience.
                    </p>
                    <p className='robotofont'>
                    The Silva method , an international dynamic meditation program , since 1996 , takes this a step further with its comprehensive mind-body healing system, offering powerful tools and techniques to foster holistic healing. If you are looking for a balanced, transformative approach to healing, consider the Silva Method as your guide. 
                    </p>
                   <div className='d-flex justify-content-center align-items-center'>
                   <button className='cssbuttons-io-button3' onClick={()=>{
                    navigate("/store/course/combo-plan")
                   }}>
                    Learn Silva Method 

                    </button>

                   </div>

                </div>



            </div>

        </div>
    )
}

export default BenefitsSection3