import React from 'react';
import './blog3.css'; // Import the external CSS

const MindBodyHealingHero = () => {
  return (
    <div className="hero-container">
      <div className="content">
        <h1 className='white-color lora-sans'>Healing the Mind and Body</h1>
        <p className='white-color robotofont'>
        Healing the mind and body is vital in today’s fast-paced world, to maintain your overall well-being. But what does it mean to truly heal? And what do we heal from?
        Healing is an intriguing topic that has interested faith healers and psychics from past years. However, with the rise of meditation in today’s modern world, healing has once again drawn much attention. In this article, let’s dig deeper into this phenomenon.
        </p>
      </div>
    </div>
  );
};

export default MindBodyHealingHero;