
import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Row } from 'react-bootstrap';
import { FaCheckCircle, FaLeaf, FaSpa, FaBrain, FaHeart, FaMoon, FaSun, FaBalanceScale } from 'react-icons/fa';
import Tilt from 'react-parallax-tilt';

const BeginnerSection = () => {
  const guidedMeditationBenefits = [
    { icon: <FaLeaf />, title: "Structured Approach", text: "Guided meditation offers a structured approach to quieting the mind, making it easier to focus and relax." },
    { icon: <FaSpa />, title: "Instant Calm", text: "A brief guided meditation session can instantly calm your thoughts and bring clarity to your day, even for just a few minutes." },
    { icon: <FaBrain />, title: "Deepen Your Practice", text: "Enrolling in a guided meditation course helps deepen your practice with step-by-step guidance and proven techniques." },
    { icon: <FaHeart />, title: "Tailored Sessions", text: "Using guided meditation scripts allows for tailored sessions that suit specific needs like relaxation, healing, or anxiety relief." },
    { icon: <FaMoon />, title: "Perfect for Busy Schedules", text: "A 5-minute guided meditation is perfect for a busy schedule, delivering mindfulness benefits in a short amount of time." },
    { icon: <FaSun />, title: "Boost Positivity", text: "Start your day with a guided morning meditation to boost positivity, energy, and focus throughout your day." },
    { icon: <FaBalanceScale />, title: "Emotional Balance", text: "Guided meditation for healing can foster emotional balance and physical rejuvenation, promoting overall well-being." },
    { icon: <FaSpa />, title: "Effective Relief", text: "Battling both insomnia and stress, guided meditation for sleep and anxiety sessions offer effective relief from restless thoughts and tension." }
  ];

  const nonGuidedMeditationBenefits = [
    "If you're experienced with guided meditation and feel confident meditating on your own, non-guided sessions can offer deeper, self-led introspection.",
    "After completing a guided meditation course, practicing non-guided meditation allows you to apply techniques independently, without relying on guided meditation scripts.",
    "If you've consistently used brief guided meditations or a 5-minute guided meditation, transitioning to non-guided meditation helps build focus for longer sessions.",
    "A regular routine of guided morning meditation can lead to a desire for more flexible, non-guided meditation to adapt to your evolving needs.",
    "If you've practiced guided meditation for healing, you might feel ready to meditate alone, trusting your own awareness to bring about healing.",
    "For those who no longer need the structure of a quick guided meditation or a guided meditation for sleep, non-guided practice can offer a calming and unstructured alternative.",
    "If guided meditation for anxiety has helped you manage stress, non-guided meditation may become your next step for personal empowerment and self-soothing."
  ];

  return (
    <div className="beginner-section container">
      <h3 className="main-title lora-sans">Guided <span className="highlight">Meditation</span> For Beginners</h3>

      <div className='d-flex justify-content-center align-items-center my-3'>
        <Tilt>
          <img src='/img/bg/bghero/tdjdjcycaflf7suh8vij.jpg' className='mx-auto mb-3' style={{
            borderRadius: "15px",
            boxShadow: "0 0 0 2px #8019da, 8px 8px 0 0 #8019da"

          }} alt='Guided-Meditation-For-Beginners'/>
        </Tilt>

      </div>

      <p className="intro-text robotofont">
        Guided meditation for beginners is a simple and effective way to ease into the practice of meditation, especially if you're just starting out. With a guide leading you through the process, you can focus on calming your mind and body without worrying about how to meditate on your own. Whether you're exploring meditation to reduce stress or improve focus, guided sessions offer a structured path to achieving relaxation and clarity.
      </p>

      <h2 className="sub-title lora-sans">Benefits of <span className="highlight">Guided Meditation</span></h2>

      <div className="benefits-grid">
        {guidedMeditationBenefits.map((benefit, index) => (
          <div key={index} className="benefit-card">
            <div className="benefit-icon">{benefit.icon}</div>
            <h3>{benefit.title}</h3>
            <p>{benefit.text}</p>
          </div>
        ))}
      </div>

      <h2 className="sub-title lora-sans">Guided vs Non-Guided <span className="highlight">Meditation</span></h2>

      <p className="intro-text">
        When exploring meditation, the choice between guided meditation and non-guided meditation can significantly impact your experience. Both have unique benefits, depending on your needs, preferences, and goals. Understanding the differences between these two meditation styles can help you choose the one that best supports your journey to mindfulness, healing and relaxation.
      </p>

      {/* <div className="comparison-section">
        <div className="guided-column">
          <h3>Guided Meditation</h3>
          <ul>
            {guidedMeditationBenefits.map((benefit, index) => (
              <li key={index}><span style={{
                flex:"none"
              }}><FaCheckCircle className="check-icon" /></span> {benefit.title}</li>
            ))}
          </ul>
        </div>
        <div className="non-guided-column">
          <h3>Non-Guided Meditation</h3>
          <ul>
            {nonGuidedMeditationBenefits.map((benefit, index) => (
              <li key={index}><span style={{
                flex:"none"
              }}><FaCheckCircle className="check-icon" /></span> {benefit}</li>
            ))}
          </ul>
        </div>
      </div> */}
      <Row className='gy-3 mb-5 mt-3'>
        {
          nonGuidedMeditationBenefits.map((val, index) => (
            <Col key={index} md={6} lg={4}>
              <Fade direction="up" cascade damping={index + 1}>
                <div
                  className="d-flex align-items-start p-3 benefitscardcourse"

                >
                  <span style={{
                    flex: "none"
                  }}>
                    <FaCheckCircle className="text-success me-3" size={28} />
                  </span>
                  <div>
                    <p style={{ fontSize: '1rem', marginBottom: 0, color: "black", fontWeight: "500" }} className='robotofont'>{val}</p>
                  </div>
                </div>
              </Fade>
            </Col>
          ))
        }


      </Row>

      <style jsx>{`
        .beginner-section {
          max-width: 1200px;
          margin: 0 auto;
          padding: 40px 20px;
          color: #333;
        }
        .main-title, .sub-title {
          text-align: center;
          margin-bottom: 30px;
        }
        .main-title {
          font-size: 2.5rem;
          font-weight: bold;
        }
        .sub-title {
          font-size: 2rem;
        }
        .highlight {
          color: #572ac7;
        }
        .intro-text {
          font-size: 1.1rem;
          line-height: 1.6;
          margin-bottom: 40px;
          text-align: center;
        }
        .benefits-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          gap: 30px;
          margin-bottom: 60px;
        }
        .benefit-card {
          background-color: #f8f8f8;
          border-radius: 10px;
          padding: 20px;
          text-align: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }
        .benefit-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        }
        .benefit-icon {
          font-size: 2.5rem;
          color: #572ac7;
          margin-bottom: 15px;
        }
        .benefit-card h3 {
          font-size: 1.2rem;
          margin-bottom: 10px;
        }
        .benefit-card p {
          font-size: 0.9rem;
        }
        .comparison-section {
          display: flex;
          gap: 40px;
          margin-top: 40px;
        }
        .guided-column, .non-guided-column {
          flex: 1;
          background-color: #f8f8f8;
          border-radius: 10px;
          padding: 30px;
        }
        .guided-column h3, .non-guided-column h3 {
          text-align: center;
          margin-bottom: 20px;
          color: #572ac7;
        }
        ul {
          list-style-type: none;
          padding: 0;
        }
        li {
          margin-bottom: 15px;
          display: flex;
          align-items: center;
        }
        .check-icon {
          color: #4CAF50;
          margin-right: 10px;
        }
        @media (max-width: 768px) {
          .comparison-section {
            flex-direction: column;
          }
        }
      `}</style>
    </div>
  );
};

export default BeginnerSection;