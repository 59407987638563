import React, { useMemo } from 'react'
import TypesMeditateCard2 from '../BlogLandingPage2/TypesMeditateCard2'
import { Col, Row } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'

function LastSection() {

    const navigate = useNavigate()
    const healingtypes = useMemo(() => {
        return [
            {
                title: "Guided Meditation for Healing",
                content: "One of the most accessible forms of healing meditation, guided meditation for healing involves following a narrator or audio guide who takes you through specific visualization and breathing techniques. It’s especially beneficial for those new to meditation or anyone looking to stay focused throughout the session. Guided meditation can lead you into a deeply relaxed state, where your body’s natural healing mechanisms are activated. Many practitioners find this form effective in reducing stress, anxiety, and even chronic pain by aligning the mind and body towards recovery.",
                img: "/img/bg/bghero/icons/Guided meditation for healing.webp",
            }
        ]
    }, [])


    const healingtypes2 = [
        {
            title: "Self Healing Meditation",
            content: "Self healing meditation is all about tapping into your own inner energy to heal the body and mind. This practice encourages you to focus on your own power to restore balance and wellness for healing the mind and body. Through intentional breathwork, visualization, and focus, self healing meditation can help you heal emotionally, spiritually, and physically. This form of meditation is often used as a daily practice to strengthen resilience, foster emotional health, and support recovery from illnesses. It’s particularly empowering because it teaches you to rely on your own capabilities, without external guidance.",
            img: "/img/bg/bghero/icons/Self Healing.webp",
        },
        {
            title: "15 Minute Healing Meditation",
            content: "If time is a constraint, a 15 minute healing meditation can be the perfect solution. Short but powerful, this practice allows you to quickly reset your mind and body in the middle of a busy day. By dedicating just a small portion of your time to deep breathing and mindfulness techniques, you can significantly reduce stress, lower blood pressure, and enhance your body’s healing responses. This short healing meditation is perfect for beginners or for those who struggle to fit longer sessions into their daily routine. It’s amazing how just 15 minutes can shift your mood, energy, physical state and kickstart the process of healing the mind and body. ",
            img: "/img/bg/bghero/icons/15 mins meditation.webp",
        },
        {
            title: "Morning Meditation for Healing:",
            content: "Starting your day with morning meditation for healing can set a positive tone for the rest of the day. Morning meditation for healing is practiced early in the day when the mind is fresh and clear. It involves focusing on intentions for healing and well-being while breathing deeply and calming the mind, making you ready for healing the mind and body. Morning healing meditation can help boost your immune system, improve emotional resilience, and prepare you to face daily challenges with more ease and balance. The morning is an ideal time because it allows you to carry this positive energy throughout your day.",
            img: "/img/bg/bghero/icons/morning meditation.webp",
        }
    ]

    const healingtypes3 = [
        {
            title: "Meditation for Relaxation and Healing",
            content: "One of the core goals of healing meditation is relaxation. Meditation for relaxation and healing focuses specifically on calming the nervous system, reducing tension in the body, and allowing the mind to release stressful thoughts. This form of meditation often incorporates deep breathing, progressive muscle relaxation, and calming visualizations, helping the body relax deeply so that healing can occur naturally. Regular practice can not only enhance your physical well-being but also improve mental health by reducing symptoms of anxiety, depression, and emotional distress.",
            img: "/img/bg/bghero/icons/Guided meditation for healing.webp",

        },
        {
            title: "Mindful Meditation Healing",
            content: "Mindful meditation healing takes the traditional practice of mindfulness and applies it to the healing process. This type of meditation involves staying fully present in the moment while focusing on sensations within the body, especially areas of pain or discomfort. Instead of pushing those feelings away, mindful meditation encourages you to observe them without judgment, allowing the mind to relax and the body to begin healing. By bringing awareness to the present moment, you can promote both physical and emotional recovery. This type of healing meditation is particularly effective for managing chronic pain and emotional wounds.",
            img: "/img/bg/bghero/icons/Mindful Meditation.webp",
        },

    ]
    return (
        <div className='container pb-5'>

            <h2 className='lora-sans'>
                Types of Healing Meditation
            </h2>
            <p className='robotofont'>
                Now that we’ve gained a deeper understanding of the powerful connection between mind and body in the healing process,
                it’s time to explore the various types of healing meditation. Each type offers its unique approach and benefits,
                allowing you to choose the one that resonates most with your needs. Whether you're seeking emotional balance, pain relief, or a general boost in well-being,
                here are the different types of healing meditation you can incorporate into your practice for healing the mind and body.

            </p>

            <Row className='justify-content-center align-items-center my-5 gy-2'>
                {
                    healingtypes.map((val, index) => (
                        <Col>

                            <TypesMeditateCard2 item={val} color={"#f5f4ef"} imgSrc={val.img} black />
                        </Col>
                    ))

                }


            </Row>

            <p className='robotofont'>
                The Silva Method also offers powerful guided meditation for healing, designed to help individuals tap into their inner strength and promote both emotional and physical recovery. Here's a short, deep healing guided meditation from the Silva Method for you to practice today.
            </p>
            <div className='d-flex justify-content-center align-items-center'>
                <ReactPlayer url={"https://youtu.be/fOrmKmEJX5g?si=JyTK81A6eyHu62NP"}



                />
            </div>

            <Row className='justify-content-center align-items-center py-5'>
                {
                    healingtypes2.map((val) => (
                        <TypesMeditateCard2 item={val} color={"#f5f4ef"} imgSrc={val.img} black />
                    ))
                }


            </Row>

            <h2 className='lora-sans mb-3'>
                Silva Method Deep Relax Meditation (3-1 Method by Jose Silva)

            </h2>
            
            <div className='d-flex justify-content-center align-items-center'>
                
            <ReactPlayer
                url={"https://youtu.be/toTfc1QdC2U?si=3_YEWmZF3xptJ2kh"}
            />
            </div>

            <Row className='justify-content-center align-items-center py-5'>
                {
                    healingtypes3.map((val) => (
                        <TypesMeditateCard2 item={val} color={"#f5f4ef"} imgSrc={val.img} black />
                    ))
                }


            </Row>

            <p className='robotofont'>
                Whether you choose guided meditation for healing or prefer a quick 15 minute healing meditation, each type offers profound benefits that can help you achieve balance and vitality for healing the mind and body.

                Now as we navigate through our fast-paced world, it’s easy to overlook the power of our minds in promoting physical health. But by embracing healing meditation, we tap into our innate ability to restore balance, not only emotionally but also physically. The Silva Method Complete Program, founded by the revolutionary Jose Silva, is one such program that provides these healing meditations, guiding individuals on a journey of self-healing. If you’re ready to awaken your mind and body’s natural healing ability, the Silva Method could be the perfect companion on this journey. Learn more about the Silva Method Complete Program and start your journey. It’s time to take control of your well-being and find peace in the art of self-healing.



            </p>

            <div className='d-flex justify-content-center align-items-center'>
            <button className='cssbuttons-io-button3' onClick={()=>{
                navigate("/store/course/combo-plan")
            }}>
                Join Silva Method Complete Program

            </button>
            </div>



        </div>
    )
}

export default LastSection