import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import AudioPlayer from 'react-h5-audio-player';
import CustomAudioPlayer from './CustomAudioPlayer';
import HeadingNew from '../../../AboutUs/HeadingNew';
import { useContext } from 'react';
import { AuthContext } from '../../../../context/AllContext';
import ReactPlayer from 'react-player';
import ReactHlsPlayer from 'react-hls-player';
import { Link } from 'react-router-dom';

const SleepMeditationSection = () => {
    const { isMobile } = useContext(AuthContext)
    return (
        <>
            <div className=" sleep-meditation-section container">
                <Row className="px-4 align-items-center">
                    {/* Left Column - Image */}
                    <Col md={6} className="image-column">
                        <div className='d-flex justify-content-start align-items-center'>
                            <img
                                src="img/bg/blogpages/2-What is Sleep Meditation/sleep-meditation.jpg" // Replace with a relevant image URL
                                alt="Sleep Meditation"
                                className="img-fluid meditation-image"
                            />

                        </div>
                    </Col>

                    {/* Right Column - Text */}
                    <Col md={6} className="text-column">
                        <h3 style={{
                            color: "black",
                            fontSize: isMobile ? "1.5rem" : "2rem"
                        }}
                            className='lora-sans'
                        >
                            What is  {" "}
                            <span style={{ color: '#572ac7' }}>
                                Sleep Meditation?
                            </span>
                        </h3>

                        <p className="section-description robotofont">
                            <Link to={`/how-to-meditate-for-beginners`}><b className='text-primary'>Meditation</b></Link> empowers us to take control of our minds and our thoughts, helping to break the cycle of overthinking, especially at bedtime. Sleep meditation eases your mind and body into a state of relaxation for sleep. It uses mindfulness techniques like deep breathing, calming visualization, and focused attention to help your body and mind unwind. The goal is to release tension, reduce anxiety, and guide you into a peaceful sleep. Whether you want to end your day with a quiet moment of night meditation or establish a relaxing bedtime ritual, adding sleep meditation to your nighttime routine can significantly improve the quality of your sleep.

                        </p>
                        <p className="section-description robotofont">

                        </p>
                    </Col>
                </Row>
            </div>
            <div className='container mt-5'>
                <Row className='px-4 justify-content-center align-items-center'>
                    <Col md={6}>
                        <h3 style={{
                            color: "black",
                            fontSize: isMobile ? "1.5rem" : "2rem"
                        }}
                            className='lora-sans'
                        >
                            <span style={{ color: '#572ac7' }}>
                                Guided Meditation{" "}
                            </span>
                            for Sleep
                        </h3>
                        {
                            isMobile && (
                                <div className='d-flex justify-content-end align-items-center'>
                                    <img src='/img/bg/blogpages/3-Guided Meditation for Sleep/sleep-meditation.jpg' className=' meditation-image my-3' style={{
                                        width: "100%",
                                        borderRadius: "15px"

                                    }} />

                                </div>
                            )
                        }
                        <p className='section-description robotofont'>
                            A common way to practice sleep meditation is through guided meditation for sleep,
                            which involves a narrator guiding you through calming exercises.
                            One of its main benefits is improved sleep quality,
                            as the gentle narration and calming imagery work to quiet the mind and create a peaceful atmosphere that promotes restful sleep.
                            For those struggling with stress or anxiety, these  deep sleep guided meditations can help reduce tension,
                            lower heart rates, and ease racing thoughts, making it easier to unwind. Many people also report falling asleep faster during guided sleep meditations.
                            Free sleep meditations can be found in various formats, from audio tracks to sleep meditation videos that offer a visual aid to complement the experience.
                        </p>

                    </Col>
                    {
                        !isMobile && (
                            <Col md={6}>
                                <div className='d-flex justify-content-end align-items-center'>
                                    <img src='/img/bg/blogpages/3-Guided Meditation for Sleep/sleep-meditation.jpg' className=' meditation-image' style={{
                                        width: "95%",
                                        borderRadius: "15px"

                                    }} />

                                </div>
                            </Col>
                        )
                    }

                </Row>

            </div>
            <div className='container my-5 d-flex justify-content-center align-items-center flex-column gap-3'>
                <h3 style={{
                    color: "black",
                    fontSize: isMobile ? "1.5rem" : "2rem",
                    textAlign: "center"
                }}
                    className='lora-sans'
                >
                    Listen to a Guided Deep Sleep Meditation Now
                </h3>
                {/* <CustomAudioPlayer /> */}
                <Card className='w-100 rounded'>

                    <ReactPlayer
                        url={'https://youtu.be/1awmTQ-184I'}
                        width="100%"
                        height={"500px"}
                        controls
                    />

                    {/* <video src='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'></video> */}
                    {/* <div >
                    <video width="100%" height="100%" controls autoPlay muted>
                        <source src="https://www.youtube.com/watch?v=FOGRHBp6lvM&list=PLjVLYmrlmjGfAUdLiF2bQ-0l8SwNZ1sBl&index=1" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    </div> */}
                </Card>

            </div>
        </>
    );
}

export default SleepMeditationSection;
