import React from 'react'
import HomeHeader from '../../../Home/Home/HomeHeader/HomeHeader'
import MindBodyHealingHero from './MindBodyHealingHero'
import HealingAboutSection from './MindBodyHealing'
import HealingMeditation from './HealingMeditation'
import HealingResearchSection from './ResearchSection'
import BenefitsSection3 from './BenefitsSection3.js'
import HealingMeditationTechniques from './HealingMeditationTechniques.js'
import LastSection from './LastSection.js'
import CustomFooter from '../../../Home/Home/HomeFooter/Footer.js'
import FormSection from '../../FreeCourse/FormSection.js'

function BlogLandingPage3() {
  return (
    <div className='parallexbloglanding'>
        <HomeHeader />
        <MindBodyHealingHero />
        <HealingAboutSection />
        <HealingMeditation />
        <HealingResearchSection />
        <div className='newsec'>
        <FormSection />

        </div>
        <BenefitsSection3 />
        <HealingMeditationTechniques />
        <LastSection />
        <CustomFooter />
    </div>
  )
}

export default BlogLandingPage3